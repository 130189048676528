<script setup lang="ts">
import { ref } from 'vue';
import WhiteLogoNoText from '../assets/images/PlusTime-IT-Logo-White-No-Text.webp';
import { useAuthStore } from '../plugins/pinia/stores/auth';
import { useAdminStore } from '../plugins/pinia/stores/admin';
import { storeToRefs } from 'pinia';

const { deauthenticate } = useAuthStore();
const { authenticated } = storeToRefs(useAuthStore());
const { admin } = storeToRefs(useAdminStore());

const icon = ref('');
const text = ref('Hello');
const account = ref('');
</script>

<template>
  <v-app-bar class="main-header" height="64">
    <v-img
      alt="plustime-logo"
      class="ml-8 mr-4"
      max-height="50"
      max-width="50"
      :src="WhiteLogoNoText"
      :to="{ name: 'Home' }"
    >
    </v-img>
    <v-toolbar-title> PlusTime IT </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu v-if="authenticated" width="250" location="bottom left" offset-bottom="10">
      <template #activator="{ props }">
        <v-btn class="mr-4" icon="mdi-account" v-bind="props"></v-btn>
      </template>
      <v-list>
        <v-list-item> Name: {{ admin?.name }} </v-list-item>
        <v-list-item> Role: {{ admin?.role }} </v-list-item>
        <v-list-group color="primary">
          <v-list-item v-for="(item, i) in account" :key="i" :prepend-icon="icon" :title="text"> </v-list-item>
        </v-list-group>
        <div class="d-flex justify-center my-3">
          <v-btn width="80%" variant="outlined" color="primary" class="text-capitalize" @click="deauthenticate"
            >Sign Out
          </v-btn>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
