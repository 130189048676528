<script setup lang="ts">
import { useRouter, RouteRecord, RouteLocationNamedRaw, RouteRecordRaw, RouteRecordNormalized } from 'vue-router';
import { useDisplay } from 'vuetify';
import { usePlatformStore } from '../plugins/pinia/stores/platform';
import { useAuthStore } from '../plugins/pinia/stores/auth';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const router = useRouter();
const { drawer, rail } = storeToRefs(usePlatformStore());
const { authenticated } = storeToRefs(useAuthStore());
const { mdAndUp } = useDisplay();

interface RouteProps {
  title?: string;
  value?: string;
  'prepend-icon'?: string;
  to?: RouteLocationNamedRaw;
}

function showRouteInNav(route: RouteRecordRaw) {
  return route.meta?.requiresAuthentication
    ? authenticated.value && !route.meta.hidesOnAuthentication
      ? route.meta?.nav.showSide
      : false
    : !route?.meta?.hidesOnAuthentication && authenticated.value
    ? route.meta?.nav.showSide
    : false;
}

const navigation = computed<RouteRecordRaw[]>(() =>
  router.options.routes
    .filter((route) => showRouteInNav(route))
    .sort((a: RouteRecordRaw, b: RouteRecordRaw): number => (a?.meta?.order ?? 1) - (b?.meta?.order ?? 2))
);

const hasChildren = (route: RouteRecord | RouteRecordRaw | RouteRecordNormalized) => (route?.children?.length ?? 0) > 0;

function filterChildren(children: RouteRecordRaw[]) {
  return children
    .filter((route) => showRouteInNav(route))
    .sort((a: RouteRecordRaw, b: RouteRecordRaw): number => (a?.meta?.order ?? 1) - (b?.meta?.order ?? 2));
}

function buildParentProps(route: RouteRecordRaw) {
  const props: RouteProps = {};
  if (route.meta?.icon) props['prepend-icon'] = route.meta?.icon;
  if (hasChildren(route)) props.value = route.meta?.title;
  if (!hasChildren(route)) {
    props.to = { name: route.name };
    props.title = route.meta?.title;
  }
  return props;
}

function buildChildProps(route: RouteRecordRaw | RouteRecordNormalized) {
  const props: RouteProps = {
    to: { name: route.name },
    title: route.meta?.title
  };
  if (route.meta?.icon) props['prepend-icon'] = route.meta.icon;
  return props;
}
</script>

<template>
  <v-navigation-drawer
    v-model="drawer"
    :rail="rail"
    :width="300"
    @click="rail = false"
    :permanent="mdAndUp"
    :class="{ 'drawer-mini': !drawer }"
  >
    <VCol cols="12" class="pa-2 text-right">
      <VTooltip location="top">
        <template v-slot:activator="{ props }">
          <VBtn
            v-show="!rail"
            size="medium"
            icon="mdi-arrow-left-circle-outline"
            v-bind="props"
            @click.stop="rail = !rail"
          >
          </VBtn>
        </template>
        <span>Minimise</span>
      </VTooltip>
    </VCol>
    <v-list>
      <template v-for="(item, i) in navigation" :key="i">
        <component :is="hasChildren(item) ? 'v-list-group' : 'v-list-item'" v-bind="buildParentProps(item)">
          <template v-if="hasChildren(item)" #activator="{ props }">
            <v-list-item v-bind="props" :prepend-icon="item.meta?.icon ?? ''" :title="item.meta?.title"></v-list-item>
          </template>

          <v-list-item
            v-for="(child, x) in filterChildren(item.children as RouteRecordRaw[] ?? [])"
            :key="`child-${x}`"
            v-bind="buildChildProps(child)"
          >
          </v-list-item>
        </component>
        <v-divider v-if="item.meta?.divider ?? false" :key="i" dark class="my-4"></v-divider>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<style scoped lang="scss">
.v-list-group__items .v-list-item {
  padding-inline-start: var(--indent-padding) !important;
}
</style>
