import { defineStore } from 'pinia';
import { Admin } from 'src/contracts/Admins';

export const useAdminStore = defineStore({
  id: 'admin',
  state: () => ({
    admin: null as Admin | null
  }),
  actions: {
    
  }
});
