import 'vuetify/styles';
import { createVuetify, ThemeDefinition } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import styles from '../../styles/theme.module.scss';

const dark: ThemeDefinition = {
  dark: true,
  colors: {
    primary: styles.primaryColor,
    secondary: styles.secondaryColor,
    accent: styles.accentColor,
    grey: styles.tertiaryColor,
    lightgrey: styles.lighttextColor
  }
};

export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'dark',
    themes: {
      dark
    }
  },
  defaults: {
    VTextField: { class: 'form-fields', variant: 'plain' },
    VTextarea: { class: 'form-fields', variant: 'plain' }
  }
});
