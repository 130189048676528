import axios from '../plugins/axios';

export default {
  async authenticated(): Promise<any> {
    return await axios.get(import.meta.env.VITE_APP_BACKEND_APP_URL + '/internal/v1.0.0/admin/auth/authenticated');
  },
  async deauthenticated(): Promise<any> {
    return await axios.get(import.meta.env.VITE_APP_BACKEND_APP_URL + '/internal/v1.0.0/admin/auth/deauthenticate');
  }
};
