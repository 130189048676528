import { createApp } from 'vue';
import { vuetify, router, store } from './plugins';
import FormLoaderPlugin from 'laravel-vue-easyforms';
import axios from './plugins/axios';
import '@mdi/font/css/materialdesignicons.css';
import App from './App.vue';
import {
  DisplayBlock,
  FooterComponent,
  GeometricLoader,
  HeroBanner,
  InfoBlock,
  PolygonImage,
  PolygonReveal,
  PortfolioBlock,
  SmallServiceBlock,
  SocialLink
} from 'plustime-frontend-shared';

createApp(App)
  .use(FormLoaderPlugin, {
    backend_domain: import.meta.env.VITE_APP_BACKEND_APP_URL,
    axios_prefix: '/internal/v1.0.0',
    csrf_endpoint: '/csrf-cookie',
    required_tags_only: false,
    tags_on_placeholder: true,
    tags_on_labels: false,
    axios: axios
  })
  .use(store)
  .use(vuetify)
  .use(router)
  .component('HeroBanner', HeroBanner)
  .component('FooterComponent', FooterComponent)
  .component('DisplayBlock', DisplayBlock)
  .component('InfoBlock', InfoBlock)
  .component('PolygonImage', PolygonImage)
  .component('PolygonReveal', PolygonReveal)
  .component('PortfolioBlock', PortfolioBlock)
  .component('SmallServiceBlock', SmallServiceBlock)
  .component('SocialLink', SocialLink)
  .component('GeometricLoader', GeometricLoader)
  .mount('#app');
