import { axios } from 'plustime-frontend-shared';
import router from '../router';

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 404) {
      // not found. redirect to 404.
      router.currentRoute.value.name !== 'NotFound' && router.push({ name: 'NotFound' });
    }
    if (error.response.status === 419) {
      // unauthenticated. redirect to login page.
      router.currentRoute.value.name !== 'Login' && router.push({ name: 'Login' });
    }
    return Promise.reject(error);
  }
);

export default axios;
