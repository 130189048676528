<script setup lang="ts">
import HeaderComponent from './layout/HeaderComponent.vue';
import SidebarComponent from './layout/SidebarComponent.vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();
</script>
<template>
  <v-app>
    <HeaderComponent v-if="!route.meta.blankTemplate"></HeaderComponent>
    <SidebarComponent v-if="!route.meta.blankTemplate"></SidebarComponent>
    <v-main class="content">
      <v-container fluid v-if="route.meta.hasContainer" class="pa-0">
        <v-card variant="flat" class="glow-border main-card">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>{{ route.meta.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn size="large" variant="outlined" color="tertiary" class="text-capitalize" @click="router.go(-1)">
              Go Back
            </v-btn>
          </v-toolbar>
          <v-sheet color="grey" class="pa-5">
            <v-row>
              <v-col cols="12">
                <router-view />
              </v-col>
            </v-row>
          </v-sheet>
        </v-card>
      </v-container>
      <v-container fluid v-else class="pa-0 no-container">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped lang="scss">
.main-card {
  border-radius: 0px;
}
.no-container {
  overflow: visible;
  display: flex;
  flex-grow: 0 1 auto;
  height: 100%;
}
</style>
