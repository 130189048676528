import { defineStore, storeToRefs } from 'pinia';
import axios from '../../axios';
import { useAdminStore } from './admin';
import router from '../../router';
import authService from '../../../service/AuthService';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    authenticated: false
  }),
  actions: {
    isAuthenticated(authed?: boolean | null): boolean {
      this.authenticated = authed ?? this.authenticated;
      return this.authenticated;
    },
    async checkAuthentication(): Promise<boolean> {
      const { admin } = storeToRefs(useAdminStore());
      await authService.authenticated()
      .then(response => {
        this.authenticated = true;
        admin.value = response.data;
      })
      .catch(error => {
        this.authenticated = false;
        admin.value = null;
      });

      return this.authenticated;
    },
    async deauthenticate(): Promise<boolean> {
      const { admin } = storeToRefs(useAdminStore());
      await authService.deauthenticated();
      this.authenticated = false;
      admin.value = null;
      router.push({ name: 'Login' });
      return true;
    },
  }
});
