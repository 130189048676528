import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router';
import { usePlatformStore } from '../pinia/stores/platform';
import { useAuthStore } from '../pinia/stores/auth';

const routes: RouteRecordRaw[] = [
  {
    path: '/404',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notfound" */ '../../pages/404Page.vue'),
    meta: {
      title: '404 -Not Found',
      hasContainer: true,
      blankTemplate: false,
      requiresAuthentication: false,
      hidesOnAuthentication: true,
      order: 1,
      nav: {
        showTop: false,
        showBottom: false,
        showSide: false
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../../pages/LoginView.vue'),
    meta: {
      title: 'Login',
      hasContainer: true,
      blankTemplate: false,
      requiresAuthentication: false,
      hidesOnAuthentication: true,
      order: 1,
      nav: {
        showTop: false,
        showBottom: false,
        showSide: true
      }
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../../pages/HomeView.vue'),
    meta: {
      title: 'Dashboard',
      hasContainer: true,
      blankTemplate: false,
      requiresAuthentication: true,
      hidesOnAuthentication: false,
      order: 5,
      icon: 'mdi-home',
      nav: {
        showTop: false,
        showBottom: false,
        showSide: true
      }
    }
  },
  {
    path: '/guests',
    name: 'Guests',
    component: () => import(/* webpackChunkName: "guests" */ '../../pages/Templates/FullWidthParent.vue'),
    meta: {
      title: 'Guest Pages',
      hasContainer: true,
      blankTemplate: false,
      requiresAuthentication: true,
      hidesOnAuthentication: false,
      order: 6,
      icon: 'mdi-text-box-outline',
      nav: {
        showTop: false,
        showBottom: false,
        showSide: true
      }
    },
    children: [
      {
        path: 'pages-builder',
        name: 'Page Builder',
        component: () => import(/* webpackChunkName: "guests" */ '../../pages/PageBuilder/Builder.vue'),
        meta: {
          title: 'Page Builder',
          hasContainer: false,
          blankTemplate: false,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 0,
          icon: 'mdi-file-document-outline',
          nav: {
            showTop: false,
            showBottom: false,
            showSide: false
          }
        }
      },
      {
        path: 'pages-builder/:id',
        name: 'Page Builder Editor',
        component: () => import(/* webpackChunkName: "guests" */ '../../pages/PageBuilder/Builder.vue'),
        meta: {
          title: 'Page Builder',
          hasContainer: false,
          blankTemplate: false,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 0,
          icon: 'mdi-file-document-outline',
          nav: {
            showTop: false,
            showBottom: false,
            showSide: false
          }
        }
      },
      {
        path: 'pages-preview/:id',
        name: 'Page Preview',
        component: () => import(/* webpackChunkName: "guests" */ '../../pages/PageBuilder/Preview.vue'),
        meta: {
          title: 'Page Preview',
          hasContainer: false,
          blankTemplate: true,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 0,
          icon: '',
          nav: {
            showTop: false,
            showBottom: false,
            showSide: false
          }
        }
      },
      {
        path: 'pages',
        name: 'Pages',
        component: () => import(/* webpackChunkName: "guests" */ '../../pages/GuestPages/PageView.vue'),
        meta: {
          title: 'Pages',
          hasContainer: true,
          blankTemplate: false,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 0,
          icon: 'mdi-file-document-outline',
          nav: {
            showTop: false,
            showBottom: false,
            showSide: true
          }
        }
      },
      {
        path: 'pages/:id',
        name: 'PagesEdit',
        component: () => import(/* webpackChunkName: "guests" */ '../../pages/GuestPages/PageEditView.vue'),
        meta: {
          title: 'Edit Page',
          hasContainer: true,
          blankTemplate: false,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 0,
          nav: {
            showTop: false,
            showBottom: false,
            showSide: false
          }
        }
      },
      {
        path: 'page-meta',
        name: 'PageMeta',
        component: () => import(/* webpackChunkName: "guests" */ '../../pages/GuestPages/PageMetaView.vue'),
        meta: {
          title: 'Page Meta',
          hasContainer: true,
          blankTemplate: false,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 0,
          icon: 'mdi-file-cog-outline',
          nav: {
            showTop: false,
            showBottom: false,
            showSide: true
          }
        }
      },
      {
        path: 'page-meta/:id',
        name: 'PageMetaEdit',
        component: () => import(/* webpackChunkName: "guests" */ '../../pages/GuestPages/PageMetaEditView.vue'),
        meta: {
          title: 'Edit Page Meta',
          hasContainer: true,
          blankTemplate: false,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 0,
          nav: {
            showTop: false,
            showBottom: false,
            showSide: false
          }
        }
      },
      {
        path: 'page-meta-tags',
        name: 'Page Meta Tags',
        component: () => import(/* webpackChunkName: "guests" */ '../../pages/GuestPages/PageMetaTagsView.vue'),
        meta: {
          title: 'Page Meta Tags',
          hasContainer: true,
          blankTemplate: false,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 0,
          icon: 'mdi-text-box-search-outline',
          nav: {
            showTop: false,
            showBottom: false,
            showSide: true
          }
        }
      },
      {
        path: 'page-meta-tags/:id',
        name: 'PageMetaTagsEdit',
        component: () => import(/* webpackChunkName: "guests" */ '../../pages/GuestPages/PageMetaTagsEditView.vue'),
        meta: {
          title: 'Edit Page Meta',
          hasContainer: true,
          blankTemplate: false,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 0,
          nav: {
            showTop: false,
            showBottom: false,
            showSide: false
          }
        }
      }
    ]
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "services" */ '../../pages/UsersPage.vue'),
    meta: {
      title: 'Users',
      hasContainer: true,
      blankTemplate: false,
      requiresAuthentication: true,
      hidesOnAuthentication: false,
      order: 10,
      icon: 'mdi-account-circle',
      nav: {
        showTop: false,
        showBottom: false,
        showSide: true
      }
    },
    children: [
      {
        path: 'admins',
        name: 'ManageAdmins',
        component: () => import(/* webpackChunkName: "services" */ '../../pages/Users/AdminView.vue'),
        meta: {
          title: 'Manage Admins',
          hasContainer: true,
          blankTemplate: false,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 1,
          icon: 'mdi-account-group-outline',
          nav: {
            showTop: false,
            showBottom: false,
            showSide: true
          }
        }
      },
      {
        path: 'admins/:id',
        name: 'AdminsEdit',
        component: () => import(/* webpackChunkName: "services" */ '../../pages/Users/AdminEditView.vue'),
        meta: {
          title: 'Edit Admin',
          hasContainer: true,
          blankTemplate: false,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 1,
          icon: 'mdi-account-group-outline',
          nav: {
            showTop: false,
            showBottom: false,
            showSide: false
          }
        }
      },
      {
        path: 'subscribers',
        name: 'Subscribers',
        component: () => import(/* webpackChunkName: "services" */ '../../pages/Users/SubscriberView.vue'),
        meta: {
          title: 'Subscribers',
          hasContainer: true,
          blankTemplate: false,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 3,
          icon: 'mdi-bell-outline',
          nav: {
            showTop: false,
            showBottom: false,
            showSide: true
          }
        }
      },
      {
        path: 'subscribers/:id',
        name: 'SubscriberEdit',
        component: () => import(/* webpackChunkName: "services" */ '../../pages/Users/SubscriberEditView.vue'),
        meta: {
          title: 'Edit Subscriber',
          hasContainer: true,
          blankTemplate: false,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 3,
          nav: {
            showTop: false,
            showBottom: false,
            showSide: false
          }
        }
      },
      {
        path: 'enquiries',
        name: 'Enquiries',
        component: () => import(/* webpackChunkName: "services" */ '../../pages/Users/EnquiryView.vue'),
        meta: {
          title: 'Enquiries',
          hasContainer: true,
          blankTemplate: false,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 4,
          icon: 'mdi-email-heart-outline',
          nav: {
            showTop: false,
            showBottom: false,
            showSide: true
          }
        }
      },
      {
        path: 'enquiries/:id',
        name: 'EnquiriesEdit',
        component: () => import(/* webpackChunkName: "services" */ '../../pages/Users/EnquiryEditView.vue'),
        meta: {
          title: 'Edit Enquiries',
          hasContainer: true,
          blankTemplate: false,
          requiresAuthentication: true,
          hidesOnAuthentication: false,
          order: 3,
          nav: {
            showTop: false,
            showBottom: false,
            showSide: false
          }
        }
      }
    ]
  },
  // {
  //   path: '/Users/:id/edit',
  //   name: 'Edit User',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "services" */ '../../pages/ManageUsers.vue'
  //     ),
  //   beforeEnter: ifAuthenticated,
  //   meta: {
  //     title: 'Edit Users',
  //     nav: {
  //       showTop: false,
  //       showBottom: false,
  //       showSide: true,
  //     },
  //     action: 'edit_user',
  //   }
  // }
  //   {
  //     path: '/Users/add',
  //     name: 'Create User',
  //     component: ManageUsers,
  //     beforeEnter: ifAuthenticated,
  //     meta: {
  //       title: 'Home',
  //       showTopNav: true,
  //       showBottomNav: false,
  //     }
  //   }
  // ]
  // },
  // {
  //   path: '/cms/service',
  //   name: 'Manage Service Block',
  //   component: ServiceBlock,
  //   beforeEnter: ifAuthenticated,
  //   meta: {
  //     title: 'Home',
  //     showTopNav: true,
  //     showBottomNav: false,
  //   },
  //   children: [
  //     {
  //       path: '/cms/service/:id/edit',
  //       name: 'Edit Service',
  //       component: ServiceBlock,
  //       beforeEnter: ifAuthenticated,
  //       meta: {
  //         title: 'Home',
  //         showTopNav: true,
  //         showBottomNav: false,
  //       }
  //     },
  //     {
  //       path: '/cms/service/add',
  //       name: 'Create Service',
  //       component: ServiceBlock,
  //       beforeEnter: ifAuthenticated,
  //       meta: {
  //         title: 'Home',
  //         showTopNav: true,
  //         showBottomNav: false,
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/cms/portfolio',
  //   name: 'Manage Portfolio Block',
  //   component: PortfolioBlock,
  //   beforeEnter: ifAuthenticated,
  //   meta: {
  //     title: 'Home',
  //     showTopNav: true,
  //     showBottomNav: false,
  //   },
  //   children: [
  //     {
  //       path: '/cms/portfolio/:id/edit',
  //       name: 'Edit Portfolio',
  //       component: PortfolioBlock,
  //       beforeEnter: ifAuthenticated,
  //       meta: {
  //         title: 'Home',
  //         showTopNav: true,
  //         showBottomNav: false,
  //       }
  //     },
  //     {
  //       path: '/cms/portfolio/add',
  //       name: 'Create Portfolio',
  //       component: PortfolioBlock,
  //       beforeEnter: ifAuthenticated,
  //       meta: {
  //         title: 'Home',
  //         showTopNav: true,
  //         showBottomNav: false,
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/support/dashboard',
  //   name: 'Support',
  //   component: ManageTicket,
  //   beforeEnter: ifAuthenticated,
  //   meta: {
  //     title: 'Home',
  //     showTopNav: true,
  //     showBottomNav: false,
  //   },
  //   children: [
  //     {
  //       path: '/support/ticket/:id/edit',
  //       name: 'Edit Ticket',
  //       component: ManageTicket,
  //       beforeEnter: ifAuthenticated,
  //       meta: {
  //         title: 'Home',
  //         showTopNav: true,
  //         showBottomNav: false,
  //       }
  //     },
  //     {
  //       path: '/support/ticket/add',
  //       name: 'Create Ticket',
  //       component: ManageTicket,
  //       beforeEnter: ifAuthenticated,
  //       meta: {
  //         title: 'Home',
  //         showTopNav: true,
  //         showBottomNav: false,
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/support/label',
  //   name: 'Support Label',
  //   component: ManageLabel,
  //   beforeEnter: ifAuthenticated,
  //   meta: {
  //     title: 'Home',
  //     showTopNav: true,
  //     showBottomNav: false,
  //   },
  //   children: [
  //     {
  //       path: '/support/label/:id/edit',
  //       name: 'Edit Label',
  //       component: ManageCategories,
  //       beforeEnter: ifAuthenticated,
  //       meta: {
  //         title: 'Home',
  //         showTopNav: true,
  //         showBottomNav: false,
  //       }
  //     },
  //     {
  //       path: '/support/label/add',
  //       name: 'Create Label',
  //       component: ManageCategories,
  //       beforeEnter: ifAuthenticated,
  //       meta: {
  //         title: 'Home',
  //         showTopNav: true,
  //         showBottomNav: false,
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/support/categories',
  //   name: 'Support Categories',
  //   component: ManageCategories,
  //   beforeEnter: ifAuthenticated,
  //   meta: {
  //     title: 'Home',
  //     showTopNav: true,
  //     showBottomNav: false,
  //   },
  //   children: [
  //     {
  //       path: '/support/categories/:id/edit',
  //       name: 'Edit Category',
  //       component: ManageCategories,
  //       beforeEnter: ifAuthenticated,
  //       meta: {
  //         title: 'Home',
  //         showTopNav: true,
  //         showBottomNav: false,
  //       }
  //     },
  //     {
  //       path: '/support/categories/add',
  //       name: 'Create Category',
  //       component: ManageCategories,
  //       beforeEnter: ifAuthenticated,
  //       meta: {
  //         title: 'Home',
  //         showTopNav: true,
  //         showBottomNav: false,
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/projects',
  //   name: 'Manage Project',
  //   component: ManageProject,
  //   beforeEnter: ifAuthenticated,
  //   meta: {
  //     title: 'Home',
  //     showTopNav: true,
  //     showBottomNav: false,
  //   },
  //   children: [
  //     {
  //       path: '/projects/:id/edit',
  //       name: 'Edit Project',
  //       component: ManageProject,
  //       beforeEnter: ifAuthenticated,
  //       meta: {
  //         title: 'Home',
  //         showTopNav: true,
  //         showBottomNav: false,
  //       }
  //     },
  //     {
  //       path: '/projects/add',
  //       name: 'Add Project',
  //       component: ManageProject,
  //       beforeEnter: ifAuthenticated,
  //       meta: {
  //         title: 'Home',
  //         showTopNav: true,
  //         showBottomNav: false,
  //       }
  //     }
  //   ]
  // }
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'NotFound' }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: () => ({ top: 0 })
});

router.beforeEach(async (to, from, next) => {
  const { isLoading } = usePlatformStore();
  const { checkAuthentication } = useAuthStore();
  isLoading(true);
  // always check authentication, even on pages where its not required.
  // this ensures the authentication data is pulled down for the user.
  const authenticated = await checkAuthentication();
  if (to.meta.requiresAuthentication && !authenticated) {
    // If authentication is required but fails, only show login
    next({
      name: 'Login',
      replace: true
    });
  } else {
    next();
  }
});
export default router;
